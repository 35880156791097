import React, {useEffect, useState} from 'react';

import {fastapi} from 'api';
import {Buttons, Structure, Typography} from 'components';
import {SubStepFamily, SubStepProfessional} from './components';
import {useMarel, useUser} from 'hooks';
import {sendTag} from 'store/Tags';

import {makeStyles} from '@material-ui/styles';
import {MAREL_SELECTS} from '../../MarelFormData';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '75%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: theme.spacing(2)
        },
        padding: theme.spacing(3, 3, 2),
        margin: theme.spacing(2, 0, 1),
        background: theme.palette.white
    }
}));

function StepSimulation({step, setStep, sessionId}) {
    const classes = useStyles();
    const [stepChildren, setStepChildren] = useState(0);
    const {user} = useUser();
    const {setMarel, marel} = useMarel();

    useEffect(() => {
        if (step === 3) setStepChildren(0);
    }, [step]);

    return (
        <div className="full-width">
            <div className={classes.container}>
                <Typography
                    variant="h2"
                    color="blue"
                >
                    Ma simulation&nbsp;&nbsp;{stepChildren + 1}/2
                </Typography>

                <Structure.Stepper
                    initialSlide={stepChildren}
                    firstAction={
                        <Buttons.Default
                            label="Étape précédente"
                            color="blueAlpha"
                            className="toLeft"
                            previous
                            onClick={() => setStep(1)} // StepLogin
                        />
                    }
                    lastAction={
                        <Buttons.Default
                            label="Étape suivante"
                            color="green"
                            next
                        />
                    }
                    onChange={(index) => setStepChildren(index)}
                    variant="transparent"
                >
                    <SubStepFamily
                        selects={MAREL_SELECTS}
                        schema={{
                            family_situation: {presence: {allowEmpty: false}},
                            children: {presence: {allowEmpty: false}},
                            children_birth_date_1: (value, attributes) =>
                                parseInt(attributes.children) < 1 ? null : {presence: {allowEmpty: false}},
                            children_birth_date_2: (value, attributes) =>
                                parseInt(attributes.children) < 2 ? null : {presence: {allowEmpty: false}},
                            children_birth_date_3: (value, attributes) =>
                                parseInt(attributes.children) < 3 ? null : {presence: {allowEmpty: false}},
                            children_birth_date_4: (value, attributes) =>
                                parseInt(attributes.children) < 4 ? null : {presence: {allowEmpty: false}},
                            children_birth_date_5: (value, attributes) =>
                                parseInt(attributes.children) < 5 ? null : {presence: {allowEmpty: false}},
                            children_birth_date_6: (value, attributes) =>
                                parseInt(attributes.children) < 6 ? null : {presence: {allowEmpty: false}},
                            children_birth_date_7: (value, attributes) =>
                                parseInt(attributes.children) < 7 ? null : {presence: {allowEmpty: false}},
                            children_birth_date_8: (value, attributes) =>
                                parseInt(attributes.children) < 8 ? null : {presence: {allowEmpty: false}}
                        }}
                        values={{
                            family_situation: 'CE',
                            children: '0'
                        }}
                    />
                    <SubStepProfessional
                        selects={MAREL_SELECTS}
                        schema={{
                            // Professional situation
                            professional_situation: {
                                presence: {allowEmpty: false}
                            },
                            situation_date: {},
                            situation_end_date: {},
                            situation_indemnity_amount: {},

                            // Professional categories
                            professional_category: {
                                presence: {allowEmpty: false}
                            },
                            activity_rate: {},
                            activity_rate_percentage: {
                                numericality: {
                                    greaterThanOrEqualTo: 0,
                                    lessThanOrEqualTo: 100,
                                    message: 'De 0 à 100'
                                }
                            },
                            salary: {
                                numericality: {
                                    greaterThanOrEqualTo: 0,
                                    message: 'Votre salaire doit être positif'
                                }
                            },
                            current_reference_rate: {
                                numericality: {
                                    greaterThanOrEqualTo: 100,
                                    lessThanOrEqualTo: 5000,
                                    message: 'De 100 à 5000'
                                }
                            },
                            salary_component_1: {},
                            salary_component_2: {},
                            month_number: {
                                numericality: {
                                    greaterThanOrEqualTo: 1,
                                    lessThanOrEqualTo: 15,
                                    message: 'De 1 à 15'
                                }
                            },
                            current_increased_index: {},
                            assignment_class_past_year: {},
                            contribution_class_past_year: {},
                            assignment_class_current_year: {},
                            oath_date: {},
                            class_change_date: {},
                            final_position: {},
                            final_grade: {},
                            grade_date: {},
                            final_bonus_code: {},
                            exercise_right_option: {},
                            premium_traction: {
                                numericality: {
                                    greaterThanOrEqualTo: 0,
                                    lessThanOrEqualTo: 10000,
                                    message: 'Compris entre 0€ et 10000€'
                                }
                            },
                            active_services_rate_percentage: {
                                numericality: {
                                    greaterThanOrEqualTo: 0,
                                    lessThanOrEqualTo: 100,
                                    message: 'De 0 à 100'
                                }
                            },
                            contribution_rate_percentage: {
                                numericality: {
                                    greaterThanOrEqualTo: 0,
                                    lessThanOrEqualTo: 100,
                                    message: 'De 0 à 100'
                                }
                            }
                        }}
                        values={{
                            professional_situation: 'ACT',
                            professional_category: 'NS',
                            activity_rate: 'Temps plein',
                            activity_rate_percentage: '100',
                            active_services_rate_percentage: '100',
                            contribution_rate_percentage: '100',
                            month_number: '12',
                            salary: '0',
                            out_of_category: 'false'
                        }}
                        submit={(stepper) =>
                            fastapi
                                .post('/simulators/marel/simulation', {
                                    user_id: user.id,
                                    payload: stepper.payload,
                                    session_id: sessionId
                                })
                                .then((response) => {
                                    let marelUpdated = response.data;
                                    delete marelUpdated.contracts;
                                    setMarel({...marel, ...marelUpdated});
                                    sendTag(user, 'cnav::finish');
                                    setStep(3); // StepMarelResult
                                })
                        }
                    />
                </Structure.Stepper>
            </div>
        </div>
    );
}

export default StepSimulation;
