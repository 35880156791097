import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import {
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    accordion: {
        borderRadius: '8px !important',
        border: 'solid 1px #E5E5E5 !important',
        marginBottom: '20px !important'
    },
    button: {
        width: '100%',
        minWidth: '100% !important',
        margin: '0 !important',
        padding: '4px 16px',
        background: 'transparent !important ',
        fontSize: '16px  !important',
        color: '#00008F !important',
        border: `1px solid transparent`,
        borderRadius: '8px !important',
        textAlign: 'left',
        '& *': {
            color: theme.palette.blue
        },
        '&:focus': {
            outline: `solid 1px ${theme.palette.blue}`
        }
    },
    details: {
        borderTop: 'solid 1px #E5E5E5'
    },
    spacer: {
        marginBottom: 20
    }
}));

function isInt(value) {
    return (
        !isNaN(value) &&
        (function (x) {
            return (x | 0) === x;
        })(parseFloat(value))
    );
}

function AccordionTiles(props) {
    const {children, initialSlide, current, ...rest} = props;
    const classes = useStyles();

    useEffect(() => {
        initialSlide && setTimeout(() => setStep(initialSlide), 100);

        // eslint-disable-next-line
    }, [initialSlide]);

    const [step, setStep] = useState(initialSlide || 0);

    useEffect(() => {
        isInt(current) && setStep(current);

        // eslint-disable-next-line
    }, [current]);

    return (
        <div {...rest}>
            {React.Children.map(children, (child, index) => (
                <MuiAccordion
                    key={`accordion-${index}`}
                    className={classes.accordion}
                    expanded={index === step}
                    onChange={() => (index === step ? setStep(-1) : setStep(index))}
                >
                    <MuiAccordionSummary
                        className={classes.button}
                        expandIcon={<KeyboardArrowDownIcon />}
                        component={'button'}
                        tabIndex={0}
                    >
                        {child.props.name}
                    </MuiAccordionSummary>
                    <MuiAccordionDetails className={classes.details}>
                        {React.cloneElement(child, {
                            step: step,
                            setStep: setStep
                        })}
                    </MuiAccordionDetails>
                </MuiAccordion>
            ))}
        </div>
    );
}

AccordionTiles.propTypes = {
    children: PropTypes.node.isRequired,
    current: PropTypes.number,
    initialSlide: PropTypes.number
};

export default AccordionTiles;
