import moment from 'moment';

export const formatMarelContracts = (marel_contracts) => {
    marel_contracts.forEach((contract) => {
        contract.type = contract.product_type;
        contract.balance = contract.value.value / 100;
        contract.title = contract.product_type_label;
        contract.policy_id = contract.contract_number;
        contract.date = moment(contract.value_date).format('DD/MM/YYYY');
        contract.scope = 'marel';
    });
    return marel_contracts;
};

export const getAllMarelAccounts = (accounts, marel_contracts) => {
    let aisPolicyIds = [];
    let s2eCompanyIds = [];
    let axaPolicyIds = [];

    accounts.forEach((account) => {
        if (account.scope === 's2e') {
            s2eCompanyIds.push(account.company_id);
        } else if (account.scope === 'axa') {
            axaPolicyIds.push(account.policy_id);
        } else if (account.scope === 'ais') {
            aisPolicyIds.push(account.policy_id);
        }
    });

    let S2EMarelContracts = marel_contracts.filter(
        (contract) => !contract.contract_number.includes('FDPC') && contract.contract_number.includes('-')
    );
    let AISMarelContracts = marel_contracts.filter(
        (contract) => !contract.contract_number.includes('FDPC') && contract.contract_number.includes('/')
    );
    let otherMarelContracts = marel_contracts.filter(
        (contract) =>
            !contract.contract_number.includes('FDPC') &&
            !contract.contract_number.includes('/') &&
            !contract.contract_number.includes('-')
    );

    let filteredS2EMarelContracts = [];
    let filteredAISMarelContracts = [];
    let filteredAXAMarelContracts = [];

    S2EMarelContracts.forEach((contract) => {
        if (!s2eCompanyIds?.includes(contract.contract_number.split('-')[1])) {
            filteredS2EMarelContracts.push(contract);
        }
    });

    AISMarelContracts.forEach((contract) => {
        if (!aisPolicyIds?.includes(contract.contract_number)) {
            filteredAISMarelContracts.push(contract);
        }
    });

    axaPolicyIds.forEach((axaPolicyId) => {
        filteredAXAMarelContracts = otherMarelContracts.filter(
            (contract) => !axaPolicyId?.includes(contract.contract_number)
        );
    });

    filteredS2EMarelContracts = filteredS2EMarelContracts.reduce((unique, o) => {
        if (!unique.some((obj) => obj.contract_number === o.contract_number && obj.administrator === o.administrator)) {
            unique.push(o);
        }
        return unique;
    }, []);

    filteredAXAMarelContracts = filteredAXAMarelContracts.reduce((unique, o) => {
        if (!unique.some((obj) => obj.contract_number === o.contract_number && obj.administrator === o.administrator)) {
            unique.push(o);
        }
        return unique;
    }, []);

    filteredAISMarelContracts = filteredAISMarelContracts.reduce((unique, o) => {
        if (!unique.some((obj) => obj.contract_number === o.contract_number && obj.administrator === o.administrator)) {
            unique.push(o);
        }
        return unique;
    }, []);

    let availableContracts = filteredS2EMarelContracts
        .concat(filteredAXAMarelContracts)
        .concat(filteredAISMarelContracts);

    const filteredAccountsWOMarel = accounts.filter((account) => account.scope !== 'marel');

    return filteredAccountsWOMarel.concat(formatMarelContracts(availableContracts)).sort((a, b) => {
        if (a.scope === 'axa' && b.scope !== 'axa') {
            return -1;
        }
        if (a.scope !== 'axa' && b.scope === 'axa') {
            return 1;
        }
        return b.balance - a.balance;
    });
};
