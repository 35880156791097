import React from 'react';
import {Buttons, Grid, Icon, Typography} from 'components';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '75%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        padding: theme.spacing(3, 2, 2),
        margin: theme.spacing(1, 0),
        background: theme.palette.white
    }
}));

function SubStepChannel({setStep}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h3">Vous avez un compte Info Retraite créé avec FranceConnect</Typography>
            <Typography variant="h5">
                FranceConnect est la solution proposée par l’État pour sécuriser et simplifier la connexion à vos
                services en ligne.
            </Typography>
            <Typography variant="h5">
                Veuillez cliquer sur le compte avec lequel vous souhaitez vous connecter sur votre compte Info Retraite.
            </Typography>
            <Grid
                container
                spacing={2}
            >
                <Grid item>
                    <Buttons.Default
                        label="impôts.gouv.fr"
                        color="blueLight"
                        onClick={() => setStep(2)} // SubStepLoginDgfip
                        next
                    />
                </Grid>
                <Grid item>
                    <Buttons.Default
                        label="ameli.fr"
                        color="blueLight"
                        onClick={() => setStep(3)} // SubStepLoginAmeli
                        next
                    />
                </Grid>
            </Grid>
            <Buttons.Chevron
                label="Retour"
                onClick={() => setStep(0)} // SubStepLogin
            />
            <Grid
                container
                spacing={1}
                wrap="nowrap"
                alignItems="center"
                style={{marginTop: 40}}
            >
                <Grid item>
                    <Icon
                        name="axa-icons-padlock-o"
                        size={30}
                    />
                </Grid>
                <Grid item>
                    <Typography>
                        En renseignant vos identifiants FranceConnect, vous autorisez AXA France à se connecter au site
                        Info Retraite en votre lieu et place afin de vous permettre de visualiser vos droits retraite.
                        Ces identifiants ne seront pas conservés par AXA France et demeurent sous votre reponsabilité.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default SubStepChannel;
