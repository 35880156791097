import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {fastapi} from 'api';
import {Buttons, Form, Inputs, Modal, Typography} from 'components';
import {useMarel, useUser} from 'hooks';
import {sendTag} from 'store/Tags';
import {modal} from 'utils';

import {makeStyles} from '@material-ui/styles';
import {encryptCredential} from '../../StepLogin';
import {getAllMarelAccounts} from '../../../../../../utils/accounts/marelAccounts';
import FranceConnect from '../FranceConnect';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            '& > div': {
                width: '100% !important',
                padding: theme.spacing(3, 2, 4)
            }
        },
        '& > div': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: theme.spacing(3, 4, 4),
            width: '50%'
        }
    },
    register: {
        background: theme.palette.blueLight
    },
    login: {
        background: theme.palette.white
    },
    form: {
        width: '100%',
        padding: theme.spacing(2, 0, 1)
    },
    icon: {
        padding: theme.spacing(2)
    }
}));

function SubStepLogin({setStep, setStepParent, setSessionId}) {
    const classes = useStyles();
    const {user, setUser} = useUser();
    const {setCredentials, publicKey, setMarel, marel} = useMarel();
    const [franceConnect, setFranceConnect] = useState(true);

    if (franceConnect)
        return (
            <FranceConnect
                setStep={setStep}
                setFranceConnect={setFranceConnect}
            />
        );

    return (
        <div className={classes.root}>
            <div className={classes.login}>
                <Typography
                    variant="h3"
                    semibold
                    center
                >
                    Vous avez un compte Info Retraite
                </Typography>
                <Form
                    className={classes.form}
                    schema={{
                        identifier: {
                            presence: {
                                allowEmpty: false,
                                message: 'Veuillez renseigner votre numéro de sécurité sociale'
                            },
                            format: {
                                pattern: '[12][0-9]{2}[0-1][0-9](2[AB]|[0-9]{2})[0-9]{3}[0-9]{3}',
                                message: 'Votre numéro de sécurité sociale est invalide'
                            }
                        },
                        password: {
                            presence: {
                                allowEmpty: false,
                                message: 'Veuillez renseigner votre mot de passe'
                            }
                        }
                    }}
                    submit={(formState, setFormState) =>
                        fastapi
                            .post('/simulators/marel/login/cnav', {
                                identifier: encryptCredential(formState.values.identifier, publicKey),
                                password: encryptCredential(formState.values.password, publicKey)
                            })
                            .then((response) => {
                                setCredentials({
                                    identifier: formState.values.identifier,
                                    password: formState.values.password
                                });
                                setSessionId(response.data.session_id);
                                setStepParent(2); // StepSimulation
                                sendTag(user, 'cnav::login');

                                if (response.data.session_id && user.origin !== 'collective') {
                                    fastapi
                                        .post('/simulators/marel/contracts', {session_id: response.data.session_id})
                                        .then((response) => {
                                            setMarel({...marel, ...response.data});
                                            setUser({
                                                ...user,
                                                accounts: getAllMarelAccounts(user.accounts, response.data?.contracts)
                                            });
                                            sendTag(user, 'cnav::contracts');
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                }
                            })
                            .catch((error) => {
                                let message;
                                if (error.response.status === 401) {
                                    message = "Erreur d'authentification - login et/ou mot de passe incorrect.";
                                } else if (error.response.status !== 401) {
                                    message = 'Une erreur est survenue, veuillez recommencer ultérieurement.';
                                }
                                error.response && error.response.data.errorCode === 1007
                                    ? modal.open('disclaimer_fc_register')
                                    : setFormState({
                                          ...formState,
                                          errors: {
                                              password: [error.response ? message : error.message]
                                          }
                                      });
                            })
                    }
                    values={{
                        identifier: '',
                        password: ''
                    }}
                    variant="transparent"
                >
                    <Inputs.Text
                        name="identifier"
                        label="Numéro de Sécurité Sociale (13 chiffres)"
                    />
                    <Inputs.Text
                        name="password"
                        label="Mot de passe spécifique du site de l'Info Retraite"
                        type="password"
                    />
                    <Buttons.Chevron
                        color="blueLight"
                        label="Mot de passe oublié ?"
                        onClick={() => setStepParent(5)} // StepForgotPassword
                        right
                    />
                    <Buttons.Default
                        submit
                        label="Se connecter"
                        color="blueLight"
                        next
                        center
                    />
                    <Modal
                        hash="disclaimer_fc_register"
                        title="Attention"
                    >
                        <Typography>Vous avez un compte Info Retraite créé avec FranceConnect</Typography>
                        <Buttons.Chevron
                            label="Connexion avec France Connect"
                            color="blue"
                            onClick={() => {
                                modal.close('disclaimer_fc_register');
                                setStep(1); // SubStepChannel
                            }}
                        />
                    </Modal>
                </Form>
                <Buttons.Chevron
                    label="Vous avez un compte créé avec FranceConnect"
                    onClick={() => setStep(1)} // SubStepChannel
                    center
                />
            </div>
        </div>
    );
}

SubStepLogin.propTypes = {
    setSessionId: PropTypes.func.isRequired
};

export default SubStepLogin;
