import React, {useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import {Typography} from 'components';
import {useUser} from 'hooks';

import {makeStyles} from '@material-ui/styles';
import {Box, Grid, useMediaQuery, useTheme} from '@material-ui/core';
import {profileURL} from 'utils';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    link: {
        cursor: 'pointer'
    },
    collective: {
        backgroundColor: '#4976BA',
        padding: theme.spacing(1, 2),
        borderBottom: `solid 1px #7698CB`,
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 0),
            flexDirection: 'column'
        }
    },
    item: {
        borderRight: 'solid 1px #7698CB',
        textAlign: 'center',
        padding: theme.spacing(0, 2),
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            borderRight: 'none',
            borderBottom: 'solid 1px #7698CB',
            padding: theme.spacing(0.5, 2)
        }
    },
    active: {
        fontWeight: '600',
        textDecoration: 'underline'
    },
    copyrightCollective: {
        backgroundColor: '#4976BA',
        padding: theme.spacing(0.5, 4, 0.5, 1),
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            padding: theme.spacing(0.5)
        }
    },
    iconsCollective: {
        textAlign: 'end',
        paddingRight: theme.spacing(2),
        listStyleType: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            paddingTop: theme.spacing(1),
            justifyContent: 'center'
        }
    },
    icon: {
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2)
        }
    },
    iconX: {
        width: 18,
        height: 18,
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0, 2)
        }
    }
}));

const FooterCollective = ({setFooterHeight, className, ...rest}) => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const path = window.location.pathname;

    const {user} = useUser();
    const {t} = useTranslation();

    const goTo = (endpoint) => (endpoint.startsWith('/') ? history.push(endpoint) : (window.location.href = endpoint));

    const ref = useRef();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        let cancelled = false;
        const getHeight = () => {
            const {current} = ref;
            if (!current || !current.clientHeight) {
                if (!cancelled) {
                    requestAnimationFrame(getHeight);
                }
            } else {
                setFooterHeight(current.clientHeight);
                setTimeout(() => setFooterHeight(current.clientHeight), 0);
            }
        };
        getHeight();
        return () => {
            cancelled = true;
        };

        // eslint-disable-next-line
    }, []);

    return (
        <div ref={ref}>
            <div className={classes.collective}>
                <div className={classes.item}>
                    <Typography
                        className={clsx(classes.link, path === '/mentions-legales' && classes.active)}
                        color="white"
                        caps
                        tabIndex="0"
                        onClick={() => goTo('/mentions-legales')}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                goTo('/mentions-legales');
                            }
                        }}
                        localization={'footerMenu.legal'}
                    />
                </div>
                <div className={classes.item}>
                    <Typography
                        caps
                        color="white"
                        className={clsx(classes.link, path.includes('/contact') && classes.active)}
                        tabIndex="0"
                        onClick={() =>
                            goTo(
                                user.logged && user.origin === 'collective'
                                    ? profileURL({journey: 'contact'})
                                    : '/contact'
                            )
                        }
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                goTo(
                                    user.logged && user.origin === 'collective'
                                        ? profileURL({journey: 'contact'})
                                        : '/contact'
                                );
                            }
                        }}
                        localization={'footerMenu.contact'}
                    />
                </div>
                <div className={classes.item}>
                    <Typography
                        className={clsx(classes.link, path.includes('/donnees-personnelles') && classes.active)}
                        center={!isMobile}
                        color="white"
                        caps
                        tabIndex="0"
                        onClick={() => goTo('/donnees-personnelles')}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                goTo('/donnees-personnelles');
                            }
                        }}
                        localization={'footerMenu.personalData'}
                    />
                </div>
                <div className={classes.item}>
                    <Typography
                        className={clsx(classes.link, path.includes('/politique-cookies') && classes.active)}
                        center={!isMobile}
                        color="white"
                        caps
                        tabIndex="0"
                        onClick={() => goTo('/politique-cookies')}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                goTo('/politique-cookies');
                            }
                        }}
                        localization={'footerMenu.cookies'}
                    />
                </div>
                {user.logged && user.origin === 'collective' && (
                    <div className={classes.item}>
                        <Typography
                            className={clsx(classes.link, path === '/plan-du-site' && classes.active)}
                            color="white"
                            caps
                            tabIndex="0"
                            onClick={() => goTo('/plan-du-site')}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    goTo('/plan-du-site');
                                }
                            }}
                            localization={'footerMenu.sitemap'}
                        />
                    </div>
                )}
                <Box flexGrow={1} />
                <Box sx={{minWidth: isMobile && '100%'}}>
                    <Grid
                        component={'ul'}
                        container
                        className={classes.iconsCollective}
                    >
                        <Grid
                            component={'li'}
                            item
                        >
                            <a
                                href="https://fr-fr.facebook.com/discoverAXAfr/"
                                aria-label={t('footerMenu.facebook')}
                                title={t('footerMenu.facebook')}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className={classes.iconX}
                                    src={`${process.env.PUBLIC_URL}/icons/footer/facebook.svg`}
                                    alt={t('footerMenu.facebook')}
                                />
                            </a>
                        </Grid>
                        <Grid
                            component={'li'}
                            item
                        >
                            <a
                                href="https://twitter.com/axafrance"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label={t('footerMenu.twitter')}
                                title={t('footerMenu.twitter')}
                            >
                                <img
                                    className={classes.iconX}
                                    src={`${process.env.PUBLIC_URL}/icons/footer/ic_twitter-x.svg`}
                                    alt={t('footerMenu.twitter')}
                                />
                            </a>
                        </Grid>
                        <Grid
                            component={'li'}
                            item
                        >
                            <a
                                href="https://www.youtube.com/channel/UCwfRb4aKgVEbN9OCP8JQFzA"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label={t('footerMenu.youtube')}
                                title={t('footerMenu.youtube')}
                            >
                                <img
                                    className={classes.iconX}
                                    src={`${process.env.PUBLIC_URL}/icons/footer/youtube.svg`}
                                    alt={t('footerMenu.youtube')}
                                />
                            </a>
                        </Grid>
                        <Grid
                            component={'li'}
                            item
                        >
                            <a
                                href="https://www.linkedin.com/company/axa/"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label={t('footerMenu.linkedin')}
                                title={t('footerMenu.linkedin')}
                            >
                                <img
                                    className={classes.iconX}
                                    src={`${process.env.PUBLIC_URL}/icons/footer/linkedin.svg`}
                                    alt={t('footerMenu.linkedin')}
                                />
                            </a>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className={classes.copyrightCollective}>
                <Typography
                    color="white"
                    size={12}
                >
                    © AXA {new Date().getFullYear()}{' '}
                    <Typography
                        component="span"
                        color={'white'}
                        localization={'footerMenu.allRights'}
                    />
                </Typography>
            </div>
        </div>
    );
};

FooterCollective.propTypes = {
    setFooterHeight: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default FooterCollective;
