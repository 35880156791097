import React from 'react';
import {Typography, Buttons} from 'components';
import {Box, Grid, useMediaQuery, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            '& > div': {
                width: '100% !important',
                padding: '16px 16px 16px !important'
            },
            borderRadius: 0
        },
        '& > div': {
            padding: theme.spacing(3, 4, 4)
        },
        borderRadius: 10,
        border: '1px solid #E5E5E5'
    },
    gridBox1: {
        backgroundColor: '#FAFAFA',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        paddingLeft: 48,
        paddingRight: 48,
        paddingTop: 48,
        border: '1px solid #E5E5E5',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            backgroundColor: 'white',
            border: 'none'
        }
    },
    gridBox2: {
        backgroundColor: '#F1F5FB',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        paddingLeft: 48,
        paddingRight: 48,
        paddingTop: 48,
        paddingBottom: 32,
        border: '1px solid #E5E5E5',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            backgroundColor: 'white',
            border: 'none'
        }
    },
    gridBox3: {
        margin: 8,
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    list: {
        '& > li': {
            margin: '12px'
        }
    },
    boxElements: {
        marginTop: 12,
        marginBottom: 12
    },
    button: {
        '& * span': {
            fontSize: '18px !important',
            [theme.breakpoints.down('md')]: {
                fontSize: '16px !important',
                whiteSpace: 'normal'
            }
        },
        maxWidth: 'none !important'
    },
    frConnectPlus: {
        paddingRight: '3em',
        cursor: 'pointer'
    },
    frConnect: {
        alignItems: 'flex-start',
        backgroundColor: '#000091',
        color: '#f5f5fe',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: '0.75rem',
        padding: 8,
        '&:hover': {
            backgroundColor: '#1a1aff',
            color: '#ffffff'
        },
        width: '100%',
        cursor: 'pointer'
    },
    disclaimer: {
        marginBottom: '40px',
        [theme.breakpoints.down('md')]: {
            padding: 12,
            margin: 0
        }
    }
}));

function FranceConnect({setStep, setFranceConnect}) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            sx={{background: '#FFFFFF'}}
            className={classes.root}
        >
            <Grid
                container
                spacing={0}
                direction={isTablet ? 'column' : 'row'}
                alignItems={isTablet ? 'center' : null}
                justifyContent="center"
            >
                <Grid
                    item
                    xs={12}
                    sm={6}
                    className={classes.gridBox1}
                >
                    <Typography
                        variant={isMobile ? 'h4' : 'h3'}
                        semibold
                        center
                        color={'blue'}
                    >
                        Connectez-vous à votre compte retraite
                    </Typography>

                    <Typography
                        size={isMobile ? 16 : 18}
                        className={classes.boxElements}
                    >
                        Notre simulateur nécessite une connexion préalable au site public info-retraite.fr.
                    </Typography>
                    <button
                        type="button"
                        className={clsx(classes.frConnect, classes.frConnectPlus)}
                        onClick={() => setStep(1)}
                    >
                        <Typography
                            component="span"
                            semibold
                            color="white"
                            size={isMobile ? 16 : 18}
                            center
                            gutterBottom={false}
                        >
                            S’identifier avec FranceConnect
                        </Typography>
                    </button>
                    <a
                        href="https://franceconnect.gouv.fr/france-connect-plus"
                        target="_blank"
                        rel="noopener"
                        title="Qu’est-ce que FranceConnect+ ? - nouvelle fenêtre"
                    >
                        Qu’est-ce que FranceConnect+ ?
                    </a>

                    <Typography
                        size={isMobile ? 16 : 18}
                        semibold
                        className={classes.boxElements}
                    >
                        ou avec vos identifiants Info Retraite
                    </Typography>
                    <Buttons.Default
                        className={classes.button}
                        classLabel={classes.label}
                        centerText
                        label="Se connecter avec votre compte retraite"
                        color="white_border_blue"
                        onClick={() => setFranceConnect(false)}
                        aria-controls="popup"
                        aria-haspopup="true"
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    className={classes.gridBox2}
                >
                    <Typography
                        variant={isMobile ? 'h4' : 'h3'}
                        semibold
                        center
                        color={'blue'}
                    >
                        Vous n’avez pas encore de compte ?
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            width: '100%'
                        }}
                    >
                        <Typography
                            size={isMobile ? 16 : 18}
                            className={classes.boxElements}
                        >
                            C’est <strong>simple</strong>, <strong>gratuit</strong> et <strong>rapide</strong>.
                        </Typography>
                        <Typography
                            size={isMobile ? 16 : 18}
                            className={classes.boxElements}
                        >
                            Vous pourrez ainsi:
                        </Typography>
                    </Box>
                    <ul className={classes.list}>
                        <li>
                            <Typography
                                component="span"
                                size={isMobile ? 16 : 18}
                            >
                                <strong>Accéder facilement à une vue complète et consolidée</strong> de vos droits
                                retraites
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                component="span"
                                size={isMobile ? 16 : 18}
                            >
                                <strong>Profiter d’une simulation personnalisée</strong> se basant sur des données
                                exhaustives et fiables.
                            </Typography>
                        </li>
                    </ul>
                    <Buttons.Default
                        className={classes.button}
                        label="Créer un compte retraite"
                        color="blue"
                        center
                        centerText
                        onClick={() => window.open('https://www.info-retraite.fr/portail-services/login')}
                    />
                </Grid>
            </Grid>
            <Typography
                center
                className={classes.disclaimer}
            >
                En renseignant vos identifiants, vous autorisez AXA France à se connecter au site Info Retraite en votre
                lieu et place afin de vous permettre de visualiser vos informations. Ces identifiants ne seront pas
                conservés par AXA France et demeurent sous votre responsabilité.
            </Typography>
        </Box>
    );
}

export default FranceConnect;
